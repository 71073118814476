
import { IonContent, IonPage, IonCheckbox } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mailOutline, callOutline, locationOutline } from 'ionicons/icons';

import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    InputText,
    Textarea,
    IonCheckbox,
    Toast
  },
  data() {
    return {
      contact: {} as any
    }
  },
  methods: {
    sendMail(){
      const texto = `
        <html>
            <body>
                <p>Nombre: ${this.contact.name}</p>
                <p>Email: ${this.contact.email}</p>
                <p>Mensaje: ${this.contact.message}</p>
                    </br></br>
                <p>Privacidad: ${this.contact.privacy}</p>
                <p>Uso Comercial: ${this.contact.commercial}</p>
            </body>
        </html>
        `;

        fetch('https://us-central1-novoinox-com.cloudfunctions.net/sendMail', { method: 'POST', body: JSON.stringify({
                asunto: `Contacto: ${this.contact.name}`,
                email: 'info@novoinox.com',
                mensaje: texto,
            })
        }).then(resp => {
            console.log(resp);
            this.$toast.add({severity:'success', summary: 'Mensaje Enviado', detail: 'Gracias por contactar con nosotros te responderemos con la mayor brevedad posible.', group: 'br', life: 3000});
            this.contact = {};
        });
    }
  },
  setup() {
    return {
      mailOutline, callOutline, locationOutline
    }
  }
});
