<template>
  <!--Notifications-->
  <Toast position="bottom-right" group="br" />

  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="lg-section" id="title">
        <div class="container">
          <h1 class="text-center"><strong>Contacta con nosotros</strong></h1>
        </div>
      </section>

      <section id="contact">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12840.715036853018!2d-6.151968!3d36.42906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x483f066dc23535b9!2sNovoinox%20(INOXIDABLES%20CHICLANA)!5e0!3m2!1ses!2ses!4v1633779672517!5m2!1ses!2ses" width="100%" height="360" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </section>

      <section class="md-section" id="contact">
        <div class="container">
          <ion-grid class="invisible-box">
            <ion-row>
              <ion-col size="12" size-lg="6">
                  <label for="name">Nombre*</label>
                  <InputText class="input secondary-input" id="name" type="text" v-model="contact.name" />

                  <label for="name">Email*</label>
                  <InputText class="input secondary-input" id="name" type="text" v-model="contact.email" />

                  <label for="name">Teléfono</label>
                  <InputText class="input secondary-input" id="name" type="text" v-model="contact.phone" />
              </ion-col>
              <ion-col size="12" size-lg="6">
                  <label for="comment">Mensaje*</label>
                  <Textarea v-model="contact.message" class="input" id="comment"  :autoResize="true" rows="10"/>
              </ion-col>
              <ion-col size="12" size-lg="6">
                  <ion-item class="no-ripple" lines="none">
                      <ion-checkbox slot="start" v-model="contact.privacy" color="dark"></ion-checkbox>
                      <h6>Acepto la <router-link :to="'/privacity'">Política de Privacidad</router-link> y consiento el tratamiento de mis datos personales. *</h6>
                  </ion-item>
                  <ion-item class="no-ripple" lines="none">
                      <ion-checkbox slot="start" v-model="contact.commercial" color="dark"></ion-checkbox>
                      <h6>Acepto el uso de mis datos personales para el envío de ofertas, promociones, descuentos e información comercial.</h6>
                  </ion-item>
              </ion-col>
              <ion-col size="12" size-lg="6">
                  <ion-button @click="sendMail" shape="round" color="secondary" class="button-contact" :disabled="!contact.privacy">Enviar Mensaje</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <section class="xl-section" id="contact">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2">
                  <ion-icon  :icon="mailOutline" colort="dark" slot="icon-only" class="icon-contact"></ion-icon>
                  <p class="text-center" style="padding-bottom: 0.5rem">Escríbenos</p>
                  <h5 class="text-center"><strong>info@novoinox.com</strong></h5>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2">
                  <ion-icon  :icon="callOutline" colort="dark" slot="icon-only" class="icon-contact"></ion-icon>
                  <p class="text-center" style="padding-bottom: 0.5rem">Teléfono</p>
                  <h5 class="text-center"><strong>956 53 44 41</strong></h5>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2">
                  <ion-icon  :icon="locationOutline" colort="dark" slot="icon-only" class="icon-contact"></ion-icon>
                  <p class="text-center" style="padding-bottom: 0.5rem">Nuestro Local</p>
                  <h5 class="text-center"><strong>Calle Marie Curie con Esq., Calle Severo Ochoa, 11130 Chiclana de la Frontera, Cádiz</strong></h5>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonCheckbox } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mailOutline, callOutline, locationOutline } from 'ionicons/icons';

import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    InputText,
    Textarea,
    IonCheckbox,
    Toast
  },
  data() {
    return {
      contact: {} as any
    }
  },
  methods: {
    sendMail(){
      const texto = `
        <html>
            <body>
                <p>Nombre: ${this.contact.name}</p>
                <p>Email: ${this.contact.email}</p>
                <p>Mensaje: ${this.contact.message}</p>
                    </br></br>
                <p>Privacidad: ${this.contact.privacy}</p>
                <p>Uso Comercial: ${this.contact.commercial}</p>
            </body>
        </html>
        `;

        fetch('https://us-central1-novoinox-com.cloudfunctions.net/sendMail', { method: 'POST', body: JSON.stringify({
                asunto: `Contacto: ${this.contact.name}`,
                email: 'info@novoinox.com',
                mensaje: texto,
            })
        }).then(resp => {
            console.log(resp);
            this.$toast.add({severity:'success', summary: 'Mensaje Enviado', detail: 'Gracias por contactar con nosotros te responderemos con la mayor brevedad posible.', group: 'br', life: 3000});
            this.contact = {};
        });
    }
  },
  setup() {
    return {
      mailOutline, callOutline, locationOutline
    }
  }
});
</script>

<style scoped>
  .icon-contact{
    font-size: 4rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    display: block;
  }

  #comment{
    height: 238px !important;
  }

  .button-contact{
    float: right;
    margin-top: 2rem;
  }

  @media (min-width: 960px) {
    .button-contact{
      float: right;
      margin-top: 0.5rem;
    }
  }
</style>